import Language from '../helpers/Language';
import { Stylesheet } from '../interfaces/Stylesheet';
import DateTime from '../helpers/DateTime';
import { Ticket } from '../interfaces/Ticket';


const initializeBootstrap = (ticket: Ticket) => {
  const embeddedChatService = window['embeddedservice_bootstrap'];

  try {
    embeddedChatService.settings.language = Language.getChatLanguage();
    window.addEventListener('onEmbeddedMessagingReady', () => {
      embeddedChatService.prechatAPI.setHiddenPrechatFields({
        ServiceAppointmentId: ticket.workOrder.appointment.id,
        DefaultLanguage: Language.getChatLanguage()
      });
    });

    embeddedChatService.init(
      import.meta.env.VITE_SALESFORCE_MESSAGE_CHAT_ID,
      import.meta.env.VITE_SALESFORCE_MESSAGE_CHAT_NAME,
      import.meta.env.VITE_SALESFORCE_MESSAGE_CHAT_URL,
      {
        scrt2URL: import.meta.env.VITE_SALESFORCE_MESSAGE_CHAT_SCRT_URL
      }
    );
  } catch (err) {
    console.error('Error loading Embedded Messaging: ', err);
  }
};

// The chat can only be used if its actually deployed because localhost cant be whitelisted.
const initializeChat = (ticket: Ticket, stylesheet: Stylesheet): void => {
  if (!stylesheet.salesforceChat) {
    return;
  }

  const weekday = DateTime.getDayOfTheWeek(new Date());

  const openingTimes = stylesheet.salesforceChat.chatAvailability[weekday];

  if (!openingTimes) {
    return;
  }

  const startTime = DateTime.dateFromTime(openingTimes.startTime);
  const endTime = DateTime.dateFromTime(openingTimes.endTime);
  const now = Date.now();

  if (now < startTime.getTime() || now > endTime.getTime()) {
    return;
  }

  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', `${import.meta.env.VITE_SALESFORCE_MESSAGE_CHAT_URL}/assets/js/bootstrap.min.js`);
  scriptElement.id = 'salesforce-chat-script';
  scriptElement.onload = (): void => {
    initializeBootstrap(ticket);
  };
  document.body.appendChild(scriptElement);
};

// @TODO when the contact page chat button is implemented it needs to use this to open the chat.
const startChat = () => {
  const embeddedChatService = window['embeddedservice_bootstrap'];

  if (embeddedChatService) {
    embeddedChatService.utilAPI.launchChat();
  }
};

const loadNewChatLanguage = () => {
  const embeddedChatService = window['embeddedservice_bootstrap'];

  if (embeddedChatService) {
    const language = Language.getChatLanguage();

    // Set the language for the chat bot. At the moment it is not possible to change the language of the chat itself dynamically.
    embeddedChatService.prechatAPI.setHiddenPrechatFields({
      DefaultLanguage: language
    });
  }
};

const isChatAvailable = (stylesheet: Stylesheet): boolean => {
  return typeof stylesheet.contact.whatsappNumber === 'string'
    && stylesheet.contact.whatsappNumber.length > 0
    && !!document.getElementById('salesforce-chat-script');
};

export { initializeChat, startChat, loadNewChatLanguage, isChatAvailable };
