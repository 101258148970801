import { FC, useContext } from 'react';
import classNames from 'classnames';

import { ActionContainer } from '../ActionContainer/ActionContainer';
import style from './FAQPhoneContainer.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { isChatAvailable } from '../../services/ChatService';
import { FAQComponent } from './FAQComponent';

export const FAQPhoneContainer: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const fullRowWidth = !isChatAvailable(stylesheet);

  return (
    <>
      <ActionContainer
        bgClassName={classNames({
          [style.root]: true,
          [style.noBorderMobile]: true,
          [style.noBorder]: fullRowWidth,
        })}
        innerStyle={classNames({
          [style.inner]: true,
          [style.noPaddingMobile]: true,
        })}>
        <FAQComponent/>
      </ActionContainer>
    </>
  );
};
