import { FC, useContext } from 'react';

import WhatsAppLink from '../WhatsAppLink/WhatsAppLink';
import ChatLink from '../ChatLink/ChatLink';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { isChatAvailable } from '../../services/ChatService';

export const ChatContainer: FC= () => {
  const { stylesheet } = useContext(StylesheetContext);

  if (!isChatAvailable(stylesheet)) {
    return null;
  }

  return (
    <>
      <WhatsAppLink />
      <ChatLink />
    </>
  );
};
