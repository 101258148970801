import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { isChatAvailable } from '../../services/ChatService';
import { SmallFAQCallContainer } from './SmallFAQCallContainer';
import Button from '../Button/Button';
import StepsService from '../../services/StepsService';
import style from './FAQComponent.module.scss';

export const FAQComponent: FC = () => {
  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);
  const fullRowWidth = !isChatAvailable(stylesheet);
  const navigate = useNavigate();
  const { t } = useTranslation();


  if (fullRowWidth || stylesheet.contact.showPhoneNumberInFooter) {
    return (
      <SmallFAQCallContainer fullRowWidth={fullRowWidth} renderPhone={stylesheet.contact.showPhoneNumberInFooter}/>
    );
  }

  return (
    <>
      <div className={classNames({
        [style.desktopOnly]: !stylesheet.contact.showPhoneNumberInFooter,
      })}>
        <img width={64} height={64} alt="whatsapp" src="/assets/faq.png" />

        <div className={style.content}>
          <div className={style.headerRow}>
            <span className={style.header}>{t('contact:contactPageLink')}</span>
          </div>

          <p className={style.p}>{t('contact:contactPageLinkDescription')}</p>

          <Button
            variant="outline"
            onClick={() => {
              navigate(StepsService.getStepUri(ticket?.hashId, 'contact'));
            }}
            text={t('contact:contactPageButton')}
          />
        </div>
      </div>
      <SmallFAQCallContainer fullRowWidth={fullRowWidth} renderPhone={false} mobileOnly/>
    </>

  );
};
